import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Field,
  tokens,
  makeStyles,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
} from "@fluentui/react-components";

interface CqpropertiesPorps {
  cqProperties: any;
}

/**
 * styles that has been used in the app
 */
const useStyles = makeStyles({
  cqFieldLabels: {
    fontWeight: tokens.fontWeightSemibold,
  },

  cqOfficeAddin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    paddingTop: "20px",
    paddingLeft: "10px",
    rowGap: "30px",
  },

  cqButton: {
    marginTop: "5px",
    maxWidth: "50%",
  },

  cqLabels: {
    fontWeight: tokens.fontWeightRegular,
  },
});

/**
 * function to construct common part of url for check in and undo check out
 * @param cqProps cq Properties
 * @returns url
 */
function getURL(cqProps) {
  // Create the base URL
  const basePath = `/lightning/r/${cqProps.sObjectType}/${cqProps.recordId}/view`;
  const url = new URL(basePath, cqProps.instanceUrl);

  // Append query parameters
  url.searchParams.set("c__isOffice365Collab", "true");
  url.searchParams.set("c__recordId", cqProps.recordId);
  url.searchParams.set("c__contentId", cqProps.contentId);
  if(cqProps.controlledDocumentContentId && cqProps.controlledDocumentContentId !== 'false'){
    url.searchParams.set("c__controlledDocumentContentId", cqProps.controlledDocumentContentId);
  }

  return url;
}

const CqAddin: React.FC<CqpropertiesPorps> = (props: CqpropertiesPorps) => {
  let [cqProps, setcqProps]: any = useState({});
  useEffect(() => {
    setcqProps(props.cqProperties);
  }, [props.cqProperties]);

  /**
   * action to be performed on checkin
   */
  const handleCheckin = async () => {
    const checkInUrl = new URL(getURL(cqProps));
    //Append checkin mode param
    checkInUrl.searchParams.set("c__mode", "checkin");

    // eslint-disable-next-line no-undef
    window.open(checkInUrl, "_blank", "noreferrer");
  };

  /**
   * action to be performed on undo checkout
   */
  const handleUndoCheckout = async () => {
    const undoCheckOutUrl = new URL(getURL(cqProps));
    //Append undocheckout mode param
    undoCheckOutUrl.searchParams.set("c__mode", "undocheckout");

    // eslint-disable-next-line no-undef
    window.open(undoCheckOutUrl, "_blank", "noreferrer");
  };

  const styles = useStyles();

  const isCqPropsEmpty = Object.keys(cqProps).length === 0;

  return (
    <div className={styles.cqOfficeAddin}>
      {isCqPropsEmpty && (
        <MessageBar intent="error">
          <MessageBarBody>
            <MessageBarTitle>Error:</MessageBarTitle>
            <span className={styles.cqLabels}>Invalid File for Collaboration</span>
          </MessageBarBody>
        </MessageBar>
      )}
      <Field className={styles.cqFieldLabels} label="Online Collaboration">
        <span className={styles.cqLabels}>Share the file with other users to do On Line Collaboration</span>
      </Field>
      <div>
        <Field className={styles.cqFieldLabels} label="Check In">
          <span className={styles.cqLabels}>Check In File in CQ with the latest changes</span>
        </Field>
        <Button
          className={styles.cqButton}
          appearance="primary"
          disabled={isCqPropsEmpty}
          size="medium"
          onClick={handleCheckin}
        >
          Check In
        </Button>
      </div>
      <div>
        <Field className={styles.cqFieldLabels} label="Undo Check Out">
          <span className={styles.cqLabels}>Go back to CQ and do not check in the latest changes</span>
        </Field>
        <Button
          className={styles.cqButton}
          appearance="primary"
          disabled={isCqPropsEmpty}
          size="medium"
          onClick={handleUndoCheckout}
        >
          Undo Check Out
        </Button>
      </div>
    </div>
  );
};

export default CqAddin;
